$searchIcon: #f0774a;
$blackColor: #011118;
$themeColor: #F26E4C;

.login_page {
    background: url('../../imgs/login_bg.webp') no-repeat center top;
    background-color: #120905;
    .login {
        .login_wrapper {
           .re_modal_mask_none {
               display: none;
           }
           .re_modal_mask {
            // align-items: center;
            background-color: rgba(0,0,0,.9);
            bottom: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: flex;
            inset: 0;
            justify-content: center;
            left: 0;
            margin: 0;
            min-width: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 99200;
            .re_modal_content {
                -webkit-animation: cssmodal_animation-1__2idps .3s ease-in-out 0s 1 normal none running;
                animation: cssmodal_animation-1__2idps .3s ease-in-out 0s 1 normal none running;
                border-radius: 6px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                margin: 0;
                min-width: 0;
                position: relative;
            }
           }
           width: 508px;
           height: 750px;
        //    height: 697px;
           background: linear-gradient(180deg, #0E212B 0%, #041118 100%);
           margin: 200px auto;
           border-radius: 15px;
           padding: 68px 72px;
           position: relative;
           border: 6px solid #f0774a;
           box-shadow: 0 -2px 200px 0 rgba(250,100,0,1);
           position: relative;
           .login_loading_icon {
               width: 200px;
               position: absolute;
               left: 50%;
               transform: translateX(-50%);
               top: 0px;
           }
           .wrapper_name {
               font-size: 32px;
               color: #fff;
               font-weight: bold;
               .bind_account{
                 font-size: 19px;
                 color: #07627C;
                 margin-top: 20px;
               }
               .bind_account_des{
                 font-size: 15px;
                 font-weight: 400;
                 color: rgba(255, 255, 255, 0.59);
                 margin-top: 7px;
               }
           }
           .wallet_connect_btn {
                width: 350px;
                height: 50px;
                background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
                box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 28px;
                cursor: pointer;
                .wallet_connect_icon {
                    background: url('../../imgs/ic_wallet@2x.png') center no-repeat;
                    width: 30px;
                    height: 30px;
                    background-size: contain;
                }
                .btn_name {
                    color: #fff;
                    margin-left: 5px;
                    font-weight: 700;
                    font-size: 15px;
                }
           }
           .wallet_connect_btn_metamask {
               width: 100%;
               height: 52px; // 52
               background: #BF5925;
               box-shadow: 0px 10px 14px -5px rgba(139,67,30,0.47);
               border-radius: 4px;
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 4px 10px;
               cursor: pointer;
               border: none;
               .left_icon {
                  width: 40px;
                  height: 40px;
               }
               .center_name {
                  color: #fff;
                  font-size: 15px;
                  font-weight: 700;
               }
               .right_icon {
                  width: 45px;
                  height: 45px;
               }

           }
           .wallet_connect_btn_walletcon {
                width: 100%;
                height: 52px;
                background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
                box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px 10px;
                margin-top: 30px;
                cursor: pointer;
                border: none;
                .left_icon {
                    width: 40px;
                    height: 40px;
                 }
                 .center_name {
                    color: #fff;
                    font-size: 15px;
                    font-weight: 700;
                 }
                 .right_icon {
                    width: 45px;
                    height: 45px;
                 }
           }

           .or {
                color: #9B99A2;
                margin: 30px auto 30px;
                text-align: center;
                font-size: 15px;
           }
           .welcome_wrapper_name {
               font-size: 32px;
               font-weight: 600;
               font-weight: 800;
               color: #fff;

           }
           .welcome_wrapper_des {
               font-size: 14px;
               font-weight: 400;
               color: rgba(255,255,255,.4);
               margin-top: 80px;
               margin-bottom: 18px;

           }
           .welcome_wrapper_logo {
               background: url('https://cdn.igamie.com/static/welcome.svg') center no-repeat;
               background-size: contain;
               width: 100%;
               height: 160px;
               margin-bottom: 20px;

           }

           .register_tab {
                width: 100%;
                height: 40px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 2px 4px;
                background: #fff;
                border-radius: 4px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                margin-bottom: 24px;
                .tab_item {
                    width: 194px;
                    height: 100%;
                    border-radius: 4px;
                    background: #fff;
                    font-size: 14px;
                    line-height: 36px;
                    text-align: center;
                    color: #495666;
                    color: #17494D;
                    text-decoration: none;
                    -webkit-transition: all .2s cubic-bezier(.645,.045,.355,1);
                    -o-transition: all .2s cubic-bezier(.645,.045,.355,1);
                    transition: all .2s cubic-bezier(.645,.045,.355,1);
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                .tab_item_active {
                    background-color: #17494D;
                    color: #fff;
                }
           }
           .rcer {
               color: #fff;
               position: relative;
               top: -30px;
               color: #D4A82B;
           }
           .wrapper_des {
               font-size: 14px;
               color: rgba(255,255,255, .7);
               margin-bottom: 24px;
               margin-top: 24px;

           }
           .account_form {
               box-sizing: initial;
               display: -webkit-flex;
               display: flex;
               -webkit-flex-direction: column;
               flex-direction: column;
               -webkit-justify-content: center;
               justify-content: center;
               .login_password_container {
                 .skip_button{
                   font-size: 20px;
                   text-decoration: underline;
                   text-align: center;
                   color:#07627C;
                   cursor: pointer;
                 }
                 .forget_button{
                   font-size: 13px;
                   text-decoration: underline;
                   text-align: right;
                   color:#07627C;
                   margin-bottom: 20px;
                   cursor: pointer;
                 }
                   .password_form {
                       margin-bottom: 14px;
                   }
                   .vari_code_wrapper {
                       position: relative;
                       .send_again_wrapper {
                           position: absolute;
                           right: 10px;
                           top: 13px;
                        //    top: 50%;
                        //    transform: translateY(-50%);
                           border-left: 1px solid #005050;
                           padding-left: 30px;
                           margin-right: 20px;
                           .send_again {
                               color: #0B6F91;
                               font-weight: 500;
                               font-size: 15px;
                               cursor: pointer;
                           }
                           .send_again_dis {
                                color: #0B6F91;
                                font-weight: 500;
                                font-size: 15px;
                                cursor: pointer;
                           }
                       }

                   }
                   .phone_input_wrapper {
                       position: relative;
                       .flag_menu {
                        //    width: 80px;
                           background:#1f2f38;
                           border: 1px solid rgb(29, 62, 72);
                           position: absolute;
                           top: 56px;
                           left: 0;
                           z-index: 999;
                           border-radius: 6px;
                           padding: 8px 13px;
                           .flag_item {
                               display: flex;
                               align-items: center;
                               justify-content: center;
                               margin-bottom: 10px;
                               padding: 4px 0;
                               text-align: center;
                               cursor: pointer;

                               .phone_flag {


                               }
                               .num {
                                   color: #fff;
                                   font-size: 13px;
                                   margin-left: 16px;
                               }

                           }
                       }
                       .phone_area_code {
                           z-index: 999;
                           cursor: pointer;
                           position: absolute;
                           left: 9px;
                           top: 13px;
                           color: #fff;
                           img {
                               margin-left: 5px;
                           }
                           span {
                               padding-left: 4px;
                           }
                       }
                       .ant-input-group-addon {
                           background: rgba(1, 17, 24, 0.86);
                           border-right: none;
                           box-shadow: none;
                           color: #fff;
                           span {
                               margin-left: 4px;
                           }
                       }
                       .ant-input {
                           box-shadow: none;
                           padding-left: 70px;
                       }
                   }
                   .login_btn_form_item{
                       margin-bottom: 10px;
                       .continue_wrapper {
                           display: flex;
                           justify-content: space-between;
                           .forget_pwd {
                                color: #666;
                                font-size: 13px;
                                cursor: pointer;
                                display: flex;
                                justify-content: flex-end;
                                color: #1E5E74;
                                text-decoration: underline;
                                margin-top: -5px;
                            }
                       }
                   }
                   .ant-form-item-children-icon{
                      margin-top: -12px;
                   }
                   .login_click_btn{
                       font-size: 15px;
                       padding: 5px;
                       font-weight: 600;
                        width: 105px;
                        height: 49px;
                        background: linear-gradient(90deg, #005050 0%, #003554 100%);
                        border-radius: 4px;
                        border: none;

                       &:disabled{
                           background-color: #f09d7e;
                           color: #fff;
                           border: none;
                       }
                   }
                   .login_click_btn_login {
                       height: 40px;
                   }
                   .ant-select-selection-item {
                       color: #17494D;
                   }
                   .phone_flag {
                       width: 20px;
                   }
                   .ant_input,
                   .ant_input_password {
                       border: 1px solid #095359;
                       border-radius: 4px;
                       color: #fff;
                       height: 49px;
                       &:hover,&:focus{
                           border-color: #fff;
                           box-shadow: none;
                       }

                   }
                   .ant_input_eyes_can {
                       background: url('../../imgs/eyes_can.png') center no-repeat;
                       width: 18px;
                       height: 11px;
                       background-size: contain;
                       cursor: pointer;
                   }
                   .ant_input_eyes_no {
                        background: url('../../imgs/eyes_no.png') center no-repeat;
                        width: 18px;
                        height: 11px;
                        background-size: contain;
                        cursor: pointer;
                   }

                   .email_form {
                       margin-bottom: 14px;
                       .ant_input {
                           height: 49px;
                           border-radius: 4px;
                           font-weight: 600;

                       }
                       ::-webkit-input-placeholder {
                           color: #9B99A2;
                           font-size: 14px;
                       }
                   }
                   .email_signup_form {
                       margin-bottom: 14px;
                   }
                   .password_form {
                       .ant-input-password{
                           height: 49px;
                           border-radius: 4px;
                       }
                       ::-webkit-input-placeholder {
                           color: #9B99A2;
                           font-size: 14px;
                       }

                   }

                   .ant-input-affix-wrapper-focused{
                        border-color: #fff;
                   }
                   .emial_or_phone {
                       text-align: right;
                       margin-bottom: 16px;
                       .click_btn {
                           color: #1e5e74;
                           font-size: 13px;
                           text-decoration: underline;
                           cursor: pointer;
                       }
                   }


               }
               .register_container {
                   .remember_me_check {
                       color: #c0c0c0;
                       .ant-checkbox {
                        .ant-checkbox-inner {
                            background: rgba(1, 17, 24, 0);
                            border-radius: 0;
                         }
                         &:hover {
                            .ant-checkbox-inner{
                                border-color: #c0c0c0;
                                background-color: rgba(0,0,0,0);
                            }
                          }
                       }
                       .ant-checkbox-checked {
                           .ant-checkbox-inner {
                              background-color: #f0774a;
                              border-color: #f0774a;
                           }
                           &:hover {
                            .ant-checkbox-inner{
                                border-color: #c0c0c0;
                                background-color: #f0774a;
                            }
                          }
                          &::after {
                              border-color: #f0774a;
                          }
                       }
                   }
                   .agree {
                       font-size: 14px;
                       margin-top: 4px;
                       color: #999;
                       width: 100%;
                       display: flex;
                       .check_href {
                          text-decoration: underline;
                       }
                       .check_icon {
                           width: 16px;
                           margin-right: 10px;
                           cursor: pointer;
                       }
                       .reg_check_icon {
                           margin-right: 10px;
                           .check_text {

                           }
                           .ant-checkbox-inner{
                               border-radius: 50%;
                           }
                           .ant-checkbox-checked{
                               &::after{
                                  border: none;
                               }
                           }

                       }

                   }
                   .register_not_yet{
                       color: #999;
                       text-align: center;
                       font-size: 15px;
                       margin-top: 40px;
                    //    transform: scale(.9);
                   }
                   .by_sign_up {
                       color: #999;
                       font-size: 13px;
                       display: flex;
                       a {
                           color: #1e5e74;
                       }
                   }
                   .or {
                       text-align: center;
                       color: #9b99a2;
                       font-size: 15px;
                       font-weight: 500;
                       margin: 20px auto 30px;
                   }
                   .or_sign_up {
                       margin: 0px auto 20px;
                   }
                   .social_login {
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       margin-bottom: 15px;
                       .google_img_wrapper {
                           background: url('../../imgs/ic_gl@2x.png') center no-repeat;
                           background-size: contain;
                           width: 48px;
                           height: 48px;
                           border-radius: 50%;
                           border: none;
                           margin: 0 15px;

                       }
                       .fb_img_wrapper {
                           background: url('../../imgs/ic_fb@2x.png') center no-repeat;
                           background-size: contain;
                           width: 48px;
                           height: 48px;
                           border-radius: 50%;
                           border: none;
                           margin: 0 15px;

                       }
                       .wallect_img_wrapper {
                           background-color: rgb(64,153,255);
                           border: none;
                           width: 100%;
                           height: 49px;
                           color: #fff;
                           font-size: 14px;
                           border-radius: 4px;
                           font-weight: 600;
                           margin: 0 15px;
                       }
                       .twitter_login {
                          background: url('https://cdn.igamie.com/static/twitter_logo.svg') center no-repeat;
                          background-size: contain;
                          width: 48px;
                          height: 48px;
                          margin: 0 15px;
                          cursor: pointer;
                       }
                       .discord_login {
                          background: url('https://cdn.igamie.com/static/new_logo.png') center no-repeat;
                          background-size: contain;
                          width: 48px;
                          height: 48px;
                          margin: 0 15px;
                          cursor: pointer;
                          border: none;
                       }

                   }
                   .fast_login {
                       text-align: center;
                       color: #999;
                       font-size: 12px;
                       margin-top: 5px;
                       transform: scale(.9);
                   }
                   .forget_pwd {
                       color: #666;
                       font-size: 13px;
                       cursor: pointer;
                    //    margin-right: -13px;
                    //    transform: scale(.9);
                       display: flex;
                       justify-content: flex-end;
                       color: #1e5e74;
                       text-decoration: underline;
                   }
                   .register_btn_wrapper{
                       text-align: center;
                       margin-top: 14px;
                       .register_btn {
                           color: #fff;
                           font-size: 20px;
                           text-decoration: underline;

                       }
                   }

                   .skip {
                       color: #1D5C72;
                       font-size: 20px;
                       font-weight: 600;
                       text-decoration: underline;
                    //    margin-top: 10px;
                       cursor: pointer;
                   }



               }
               .tc_wrapper {
                   margin-top: 30px;
               }



           }
        }

    }
}


@media only screen and (max-width: 640px) {
    .login_page{
        background: unset;
        background: #041118;
        // background: url('https://static-img.igamie.com/static/ic_sign_bg.png?x-oss-process=image/format,webp/resize,m_lfit,w_414,h_736/quality,q_90') no-repeat center top;
        width: 7.5rem;
        min-height: 12.5rem;
        background-size: cover;
        .login {
           .login_wrapper {
               width: 100%;
               background-color: unset;
               margin: 0 auto;
               padding: 1.39rem .35rem .35rem .35rem;
               border: none;
               box-shadow: none;
               border-radius: 0;
               .wrapper_name {
                   font-size: .44rem;
                   .bind_account{
                   font-size: .37rem;
                   color: #07627C;
                   margin-top: .40rem;
                 }
                   .bind_account_des{
                   font-size: .3rem;
                   font-weight: 400;
                   color: rgba(255, 255, 255, 0.59);
                   margin-top: .16rem;
                 }
               }
               .wallet_connect_btn {
                   width: 100%;
               }
               .email_form{
                   .ant-input {
                       border: none;
                       border-radius: 0;
                       box-shadow: none;
                       border-bottom: 1px solid #9B99A2;
                   }
               }
               .password_form {
                   .ant-input {
                       border: none;
                       border-radius: 0;
                       box-shadow: none;
                       color: #fff!important;
                   }
               }
               .password_form{
                   .ant-input-password{
                       border: none;
                       border-radius: 0;
                       box-shadow: none;
                       border-bottom: 1px solid #9B99A2;
                   }
               }
               .account_form {
                   .flag_menu {
                       left: -.33rem!important;
                   }
                   .register_container {
                       .by_sign_up {
                        //    margin-top: .8rem;
                       }
                       .agree {
                           align-items: unset;
                           width: unset;
                           margin-top: .6rem;
                       }
                   }
                   .ant_input_password {
                       border: none;
                   }
                   .email_form {
                   }
                   .email_signup_form {
                        margin-bottom: 4px;
                    }
                   .tc_wrapper {
                        margin-top: 0px;
                    }
                    .send_again_wrapper {
                        top: .3rem!important;
                        transform: unset;
                    }
               }
           }
        }

    }

}
